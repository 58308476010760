body {
  background: black;
}

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.container {
  width: calc(min(100vw, 100vh));
  height: calc(min(100vw, 100vh));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.switch-hitbox {
  bottom: 17.3%;
  right: 20%;
  width: 2%;
  height: 6.1%;
  position: absolute;
  cursor: pointer;
  /* background: yellow; opacity: 0.3; */
}

.screen {
  top: 48.8%;
  left: 21%;
  bottom: 17.7%;
  right: 36%;
  position: absolute;
  transform: rotate(0.6deg);
  font-family: monospace;
  color: #68766a;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  mix-blend-mode: dodge;
  opacity: 0.7;
  letter-spacing: 0.5rem;
  font-size: 1rem;
  filter: blur(0.7px);
  user-select: none;
}

.fadeout {
  animation: fadeoutt 2s forwards ease-out;
}


@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeoutt {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.final {
  animation: fadein 500ms forwards;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.final a:link, 
.final a:active, 
.final a:visited {
  color: white;
  text-decoration: none;
  border: 1px white solid;
  padding: 10px;
  font-size: 1rem;
}

.final a:hover {
  animation: zoomin 500ms forwards;
}

@keyframes zoomin {
  0% {
    border: 1px white solid;
    padding: 10px;
    font-size: 1rem;
  }
  100% {
    border: 200px transparent solid;
    padding: 100px;
    letter-spacing: 0.5rem;
    font-size: 1.3rem;
    text-decoration: underline;
  }
}
